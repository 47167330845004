import React, { useEffect, useState } from 'react';
import {Input, Button, Progress, Segment } from 'semantic-ui-react';
import { consulta, respuestaWEB, registra } from './funciones.js'; 
import { Table } from 'semantic-ui-react';
import './main.css';

const ChatRoom = () => {
    let intervalo = null;
    let intervaloCarga = null;
    const [texto, setTexto] = useState([]);
    const [latitud, setLatitud] = useState(0);
    const [longitud, setLongitud] = useState(0);
    const [latitudAct, setLatitudAct] = useState(0);
    const [longitudAct, setLongitudAct] = useState(0);
    const [coordenadas,setCoordenadas] = useState(''); // Variable para almacenar las coordenadas introducidas por el usuario
    const [cargando, setCargando] = useState(false);
    const [tiempoCarga, setTiempoCarga] = useState(0);
    const [tiempoCargaRestante, setTiempoCargaRestante] = useState(0);

    useEffect(() => {
        registra();
        console.log("ChatRoom component mounted");        
    }, []);

    const mostarPoblaciones = () => {
        // Base de la URL de Google Maps para direcciones
        let urlBase = "https://www.google.com/maps/dir/";
        let nombre = '';
        // Agregar cada ciudad a la URL
        texto.forEach((ciudad, index) => {
            if (index > 0) nombre += '/';
            nombre += encodeURIComponent(Object.keys(ciudad)[0]);
        });
        const origen = `${latitudAct},${longitudAct}`;
        const travelMode = "driving"; // Puedes cambiarlo a walking, bicycling, o transit
        const url = `${urlBase}${encodeURIComponent(origen)}/${nombre}`;

        // Abrir la URL en una nueva ventana del navegador
        window.open(url, '_blank');
    }


    const irPoblacion = (nombre) => {
        console.log(`Población seleccionada: ${nombre}`);
        // Aquí se puede agregar más lógica
        const origen = `${latitudAct},${longitudAct}`;
        const urlBase = "https://www.google.com/maps/dir/?api=1";
        const travelMode = "driving"; // Puedes cambiarlo a walking, bicycling, o transit
        const url = `${urlBase}&origin=${encodeURIComponent(origen)}&destination=${encodeURIComponent(nombre)}&travelmode=${travelMode}`;
        window.open(url, '_blank');
    };
    
    const verSitio = (poblacion, sitio) => {
        console.log(`Sitio seleccionado: ${sitio}`);
        // Aquí se puede agregar más lógica
        const baseUrl = "https://www.google.com/search?tbm=isch&q=";
        const query = encodeURIComponent(sitio+' de '+poblacion);
        const urlCompleta = baseUrl + query;

        // Abre la URL en una nueva pestaña
        window.open(urlCompleta, '_blank');
    };

    const verWiki = (poblacion) => {
        console.log(`Población seleccionada: ${poblacion}`);
        // Aquí se puede agregar más lógica
        const baseUrl = "https://es.wikipedia.org/wiki/";
        const query = encodeURIComponent(poblacion);
        const urlCompleta = baseUrl + query;

        // Abre la URL en una nueva pestaña
        window.open(urlCompleta, '_blank');
    }

    const funcionOKResp = (res) => {
        //Comprueba si existe continua en el json de respuesta
        if ('poblaciones' in res) {
            setTexto(res.poblaciones);
            //Calcula el tiempo de carga en función de la cantidad de poblaciones (8 seg por poblacion)
            setTiempoCarga(res.poblaciones.length * 8000);
            intervaloCarga = setInterval(() => {
                setTiempoCargaRestante(tiempoCargaRestante => tiempoCargaRestante + 200);
            }
            , 200);
            console.log('Continua');
            return;
        }
        //Recibe la respuesta definitiva que son las rutas
        clearInterval(intervalo);
        clearInterval(intervaloCarga);
        setTexto(res.rutas);
        setLatitud(0);
        setLongitud(0);
        setCoordenadas('');
        //Borra el tiempo de carga
        setTiempoCarga(0);
        setTiempoCargaRestante(0);
        console.log(res);
        setCargando(false);
    }

    const funcionErrorResp = (error) => {
        clearInterval(intervalo);
        clearInterval(intervaloCarga);
        //Borra el tiempo de carga
        setTiempoCarga(0);
        setTiempoCargaRestante(0);
        console.log(error);
        setCargando(false);
    }

    const consultaRespuesta = async (id) => {
        const data = 'hola';
        let res = await respuestaWEB(data,id,funcionOKResp,funcionErrorResp);
        console.log(res);
    }

    const funcionOK = (res) => {
        console.log(res);
    }

    const funcionError = (error) => {
        console.log(error);
    }

    //Envia una consulta al servidor para ejecutar una función python
    const enviaConsulta = async () => {
        try {
            setCargando(true);
            let latLoc =0;
            let longLoc = 0;
            if (latitud === 0 && longitud === 0) {
                const posicion = await obtenerPosicionActual();
                latLoc = posicion.coords.latitude;
                longLoc = posicion.coords.longitude;
                setLatitud(latLoc);
                setLongitud(longLoc);
            }else{
                latLoc = latitud;
                longLoc = longitud;
            }
            //Pone latitud y longitud actual, que perdura tras la busqueda
            setLatitudAct(latLoc);
            setLongitudAct(longLoc);
            setCoordenadas(`${latLoc},${longLoc}`);
    
            console.log(`Latitud: ${latLoc}, Longitud: ${longLoc}`); // Opcional: para verificación

            // Aquí puedes incluir la latitud y longitud en la data a enviar
            const data = { latitud:latLoc, longitud:longLoc }; // Ajusta esto según necesites
            const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            const funcion = 'ruta'; // Función a ejecutar por el servidor
            let res = await consulta(funcion, JSON.stringify(data), uniqueId, funcionOK, funcionError);
            intervalo = setInterval(() => { consultaRespuesta(uniqueId); }, 500);
            console.log(res);
        } catch (error) {
            console.error('Error al obtener la posición:', error);
        }
    };

    const obtenerPosicionActual = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(new Error('Geolocalización no soportada por este navegador.'));
            } else {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            }
        });
    };

    return (
        <div>
            <h1>En Ruta</h1>
            <Input
                type="text"
                placeholder="Introduce coordenadas"
                value={coordenadas}
                onChange={(e) => setCoordenadas(e.target.value)}
                onBlur={(e) => {
                    const coordinates = e.target.value.split(",").map(coord => parseFloat(coord.trim()));
                    let latitude = 0;
                    let longitude = 0;
                    if (coordinates.length>2) {
                        if (coordinates.length !== 4) {
                            alert('Coordenadas inválidas');
                            return;
                        }
                        // Convertir la parte decimal a un número flotante adecuado
                        // Esto se hace dividiendo la parte decimal por 10 elevado al número de dígitos de la parte decimal
                        let divisor = Math.pow(10, coordinates[1].toString().length);
                        let decimalComoFlotante = coordinates[1] / divisor;
                        // Sumar la parte entera y la decimal para obtener el número flotante completo
                        latitude = coordinates[0] + decimalComoFlotante;
                        // Repetir el proceso para la longitud
                        divisor = Math.pow(10, coordinates[3].toString().length);
                        decimalComoFlotante = coordinates[3] / divisor;
                        longitude = coordinates[2] + decimalComoFlotante;
                    }else{
                        latitude = coordinates[0];
                        longitude = coordinates[1];
                    }
                    setLatitud(latitude);
                    setLongitud(longitude);
                }}
            />
            <Button loading={cargando} onClick={enviaConsulta}>Enviar Posición</Button>
            {texto.length > 0 && (
                <Button onClick={mostarPoblaciones}>Mostrar Poblaciones</Button>
            )}
            {tiempoCarga > 0 && (
                <Progress style={{'padding':'1em'}} percent={Math.floor(tiempoCargaRestante / tiempoCarga * 100)} progress size='small'/>
            )}
            <Segment>
                {texto.length > 0 && 
                    <Table celled>
                        <Table.Body>
                            {texto.map(pueblo => {
                                const nombrePueblo = Object.keys(pueblo)[0];
                                return (
                                    <React.Fragment key={nombrePueblo}>
                                        <Table.Row>
                                            <Table.Cell>
                                                <strong>{nombrePueblo}</strong>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {(!nombrePueblo.includes("PROBLEMAS")) &&
                                                    <>
                                                        <Button onClick={() => irPoblacion(nombrePueblo)}>Ruta</Button>
                                                        <Button onClick={() => verWiki(nombrePueblo)}>Wikipedia</Button>
                                                    </>
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                        {pueblo[nombrePueblo] && pueblo[nombrePueblo].map(sitio => {
                                            const nombreSitio = Object.keys(sitio)[0];
                                            const descripcion = sitio[nombreSitio];
                                            return (
                                                <Table.Row key={nombreSitio}>
                                                    <Table.Cell>
                                                        {nombreSitio}
                                                        {(!nombreSitio.includes("ERROR")) && 
                                                            <span style={{'float':'right'}}>
                                                                <Button onClick={() => verSitio(nombrePueblo, nombreSitio)}>Ver</Button>
                                                                <Button onClick={() => irPoblacion(nombreSitio+", "+nombrePueblo)}>Ir</Button>
                                                                <Button onClick={() => verWiki(nombreSitio)}>Wiki</Button>
                                                            </span>
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell>{descripcion}</Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </Table.Body>
                    </Table>
                }
            </Segment>
        </div>
    );
}

export default ChatRoom;